import { useAuth0 } from '@auth0/auth0-react';
import React, { FC } from 'react';
import { StyledButton } from './StyledButton';

export const LoginButton: FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({});
  };

  return <StyledButton onClick={handleLogin}>Log In</StyledButton>;
};

export interface InterfaceTexts {
  [key: string]: any;
}

const Texts: InterfaceTexts = {
  'en-US': {
    loginDialogUserText: 'User',
    loginDialogPasswordText: 'Password',
    loginDialogPasswordPlaceholder: 'Your password',
    loginDialogUserPlaceholder: 'Your user',
    // the name of the interface (quest, neuralsearchx) is added in the component using the text
    UIEvaluationQuestion: 'Did you like',
    UIEvaluationPositiveAnswer: 'Yes',
    UIEvaluationNegativeAnswer: 'No',
    // texts for the evaluation card that redirects the user to a evaluation form
    UIEvaluationFormButtonText: 'Evaluate',
    UIEvaluationFormCardText: 'Please, tell us more about your experience',
  },
  'pt-BR': {
    loginDialogUserText: 'Usuário',
    loginDialogPasswordText: 'Senha',
    loginDialogPasswordPlaceholder: 'Sua senha',
    loginDialogUserPlaceholder: 'Seu usuário',
    // the name of the interface (quest, neuralsearchx) is added in the component using the text
    UIEvaluationQuestion: 'Você gostou do',
    UIEvaluationPositiveAnswer: 'Sim',
    UIEvaluationNegativeAnswer: 'Não',
    // texts for the evaluation card that redirects the user to a evaluation form
    UIEvaluationFormButtonText: 'Avaliar',
    UIEvaluationFormCardText: 'Conte-nos sobre sua experiência',
  },
};

export const getText = (language: string, textID: string) => {
  if (Texts[language]) {
    return Texts[language][textID];
  }
  return Texts['en-US'][textID];
};

import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import { toast } from 'react-toastify';
import { Divider, Typography, Grid } from '@material-ui/core';

import { PageWrapper, PageContent, Heading2, BodySmall } from '../../../shared/Styles';
import Loading from '../../common/Loading';
import SearchBar from '../HomePage/SearchBar';
import Navbar from '../../navigation/Navbar';
import SearchBarContext, { SearchBarProviderProps } from '../../../shared/context/SearchBarContext';

import { Helmet } from 'react-helmet';
import {
  API_BASE,
  SEARCH_ENDPOINT,
  SearchIndexOption,
  GLOBAL_TOAST_OPTIONS,
  TABLET_BREAKPOINT,
  LOCALSTORAGE_COOKIES_AGREEMENT,
} from '../../../shared/Constants';
import { AuthContext, AuthProviderProps } from '../../../shared/context/AuthContext';
import AuthDialog from '../../common/AuthDialog';
import CookiesDialog from '../../common/CookiesDialog';
import { WikiSection } from '../../../shared/Models';
import Section from './Section';
import { get_ws_url } from '../../../shared/Util';
import MaintenanceSnackBar from '../../common/MaintenanceSnackBar';

export interface MagicWikiProps {
  title: string;
  simpleSearchBar: boolean;
}

export interface Warning_type {
  [key: string]: string;
}

const MagicWikiSocket = ({ title, simpleSearchBar }: MagicWikiProps) => {
  const HandleWarning = () => {
    // Gets the maximum sensitivity level among all sections (0: safe, 1:sensitive, 2:unsafe)
    setSensitivityLevel(
      wikiSections.reduce(
        (acc, section) => (acc = acc > section.sensitivity ? acc : section.sensitivity),
        0,
      ),
    );
  };

  const send_over_websocket = (url: string, payload: string, start: number) => {
    const ws = new WebSocket(url);
    ws.onopen = () => {
      ws.send(payload);
      setSensitivityLevel(0);
    };

    ws.onmessage = (e) => {
      // Uses received message content only if the current URL is from a query.
      // Thus avoiding rendering content when user returns to Initial Page.
      if (window.location.search) {
        const message = JSON.parse(e.data);
        switch (message.status) {
          case 'end':
            const endTimestamp = performance.now();
            setEllapsedTime(Math.round((endTimestamp - start) / 10) / 100);
            setLoading(false);
            setAllSections(true);
            break;
          case 'error':
            toast.error('Something went wrong 😞', GLOBAL_TOAST_OPTIONS);
            setLoading(false);
            break;
          default:
            const entry: WikiSection = {
              name: message.section,
              order: message.order,
              results: message.results,
              sensitivity: message.sensitive,
            };
            setWikiSections((wikiSections) => [...wikiSections, entry]);
        }
      }
    };
    return ws;
  };

  //context variables
  const { user, getTokenAuthHeader, getResponse } = useContext(AuthContext) as AuthProviderProps;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { availableIndices, setAvailableIndices } = useContext(
    SearchBarContext,
  ) as SearchBarProviderProps;

  const urlParams = new URLSearchParams(useLocation().search);
  const query = urlParams.get('query') || '';
  //this parameters is used to signal that a new request should be made, even if the query is the same
  const refreshNumber = urlParams.get('refresh') || '';

  const hasQuery = query !== null && query !== '';
  //state variables
  const [loading, setLoading] = useState<boolean>(false);
  const [sensitivityLevel, setSensitivityLevel] = useState<number>(0);
  //Used to signal that all sections have been received
  const [allSections, setAllSections] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [showAuthDialog, setShowAuthDialog] = useState<boolean>(false);
  const [showCookiesDialog, setShowCookiesDialog] = useState<boolean>(() => {
    return localStorage.getItem(LOCALSTORAGE_COOKIES_AGREEMENT) !== 'true';
  });
  const [queryInputText, setQueryInputText] = useState<string>(query || '');
  //making copy of selected index to avoid adding the query examples in the state
  const [searchIndex, setSelectedSearchIndex] = useState<SearchIndexOption>({
    value: '',
    label: '',
    visible: false,
  });

  const [wikiSections, setWikiSections] = useState<WikiSection[]>([]);
  const [ellapsedTime, setEllapsedTime] = useState<number>(0);
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const [showMaintenanceSnackBar, setShowMaintenanceSnackBar] = useState<boolean>(
    process.env.REACT_APP_SCHEDULED_MAINTENANCE === 'true',
  );

  const resultsCount = wikiSections !== [] ? wikiSections.length : 0;
  const hasResults = !loading && resultsCount > 0;

  const warning: Warning_type = {
    '1': 'The following document may have sensitive content.',
    '2': 'The following document may have dangerous content.',
  };

  useEffect(
    () => {
      const fetchData = async () => {
        let headers = {};
        if (user.token) {
          headers = await getTokenAuthHeader();
        }
        const response = await getResponse(
          `${API_BASE}${SEARCH_ENDPOINT}/datasets`,
          'GET',
          headers,
        );

        if (response.status === 200) {
          const data = await response.json();
          const { indices } = data;
          setAvailableIndices(indices);
        } else {
          toast.error('Unexpected error. Try again in a few minutes 😞', GLOBAL_TOAST_OPTIONS);
        }
      };
      if (!showCookiesDialog) {
        fetchData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, showCookiesDialog],
  );

  useEffect(
    () => {
      HandleWarning();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wikiSections],
  );

  useEffect(() => {
    setQueryInputText(query);
  }, [query, refreshNumber]);

  useEffect(() => {
    const fetchData = async (query: string | null) => {
      if (query === null || query === '') {
        setLoading(false);
        setWikiSections([]);
        return;
      }

      try {
        if (websocket) {
          websocket.close();
        }
        setLoading(true);
        setWikiSections([]);
        setAllSections(false);
        const startTimestamp = performance.now();
        setWebsocket(
          send_over_websocket(
            get_ws_url('/magicwiki'),
            JSON.stringify({ title: query }),
            startTimestamp,
          ),
        );
      } catch (err) {
        setLoading(false);
      }
    };
    if (!showCookiesDialog) {
      fetchData(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, refreshNumber, showCookiesDialog]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageWrapper>
        <Navbar setShowAuthDialog={setShowAuthDialog} title={title} />
        <MagicWikiPageContent hasQuery={hasQuery}>
          <SearchBar
            query={queryInputText}
            searchIndex={searchIndex}
            simpleSearchBar={simpleSearchBar}
            hasResultsInDisplay={loading}
            setQuery={setQueryInputText}
            setSearchIndex={setSelectedSearchIndex}
            setShowUploadDialog={setShowUploadDialog}
            placeholder="Document title"
          />
          {hasResults && (
            <ResultCount>{`Generated ${resultsCount} sections in (${ellapsedTime} seconds)`}</ResultCount>
          )}
          <ErrorBoundary FallbackComponent={() => <NoResults>Error retrieving results</NoResults>}>
            <WikiContent>
              <Grid container>
                <WikiResults>
                  {query && (
                    <>
                      <Typography variant="h3">{query}</Typography>
                      <Divider style={{ background: '#9a9b9f' }} />
                    </>
                  )}
                  {allSections === true && (
                    <Warning sensitivityLevel={sensitivityLevel}>
                      <Typography variant="h6">{warning[sensitivityLevel.toString()]}</Typography>
                    </Warning>
                  )}
                  <Grid item container xs={12} sm={12}>
                    {wikiSections
                      .sort((a, b) => a.order - b.order)
                      .map((section) => {
                        return (
                          <SectionWrapper>
                            <Section name={section.name} content={section.results} />
                          </SectionWrapper>
                        );
                      })}
                  </Grid>
                </WikiResults>
                {loading && (
                  <Grid item container xs={12} sm={12} alignItems="center">
                    <Loading />
                  </Grid>
                )}
              </Grid>
              <AuthDialog showDialog={showAuthDialog} setShowDialog={setShowAuthDialog} />
              <CookiesDialog showDialog={showCookiesDialog} setShowDialog={setShowCookiesDialog} />
              <MaintenanceSnackBar
                language={navigator.language}
                showSnackBar={showMaintenanceSnackBar}
                setShowSnackBar={setShowMaintenanceSnackBar}
              />
            </WikiContent>
          </ErrorBoundary>
        </MagicWikiPageContent>
      </PageWrapper>
    </>
  );
};

export default MagicWikiSocket;

const Warning = styled.div<{ sensitivityLevel?: number }>`
  color: ${({ sensitivityLevel, theme }) => (sensitivityLevel === 2 ? theme.red : theme.orange)};
`;

const MagicWikiPageContent = styled(PageContent)<{ hasQuery?: boolean }>`
  ${({ hasQuery }) =>
    hasQuery
      ? `
   padding:24px 48px;
   width:85%;
   margin:0 auto;
   @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding:12px 24px;
    width:100%;
  }
  `
      : `
  flex:1;
  display:flex;
  align-items:center;
  justify-content:center;
`};
`;

const WikiContent = styled.div`
  width: 100%;
  margin-right: auto;
  display: flex;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.singleColumn}px) {
    flex-direction: column;
    max-width: 600px;
  }
`;

const ResultCount = styled.span`
  ${BodySmall}
  color: ${({ theme }) => theme.slate};
  padding-left: 20px;
`;

const WikiResults = styled(Grid)`
  width:650px;
  & > div {
    width:650px;}
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.singleColumn}px) {
    width:100%;
    & > div {
      width: 100%;
    }
  }

  padding: 20px;
`;
const NoResults = styled.div`
  ${Heading2}
  display: flex;
  margin-top: 16px;
  padding-bottom: 24px;
`;

const SectionWrapper = styled(Grid)`
  margin-top: 16px;
`;

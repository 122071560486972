import React, { createContext, useState } from 'react';

export interface AvailableIndex {
  name: string;
  label: string;
  visible: boolean;
}

export interface AvailableIndicesMap {
  [key: string]: AvailableIndex;
}

export interface childrenProps {
  children: React.ReactNode;
}

export interface SearchBarProviderProps {
  showComparison: boolean;
  setShowComparison: (showComparison: boolean) => void;
  availableIndices: AvailableIndicesMap;
  setAvailableIndices: (showComparison: AvailableIndicesMap) => void;
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
  email: string;
  setEmail: (email: string) => void;
}

const SearchBarContext = createContext<SearchBarProviderProps | null>(null);

export const SearchBarProvider = ({ children }: childrenProps) => {
  const [showComparison, setShowComparison] = useState<boolean>(false);
  const [availableIndices, setAvailableIndices] = useState<AvailableIndicesMap>({});
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const providerValue: SearchBarProviderProps = {
    showComparison,
    setShowComparison,
    availableIndices,
    setAvailableIndices,
    isChecked,
    setIsChecked,
    email,
    setEmail,
  };
  return <SearchBarContext.Provider value={providerValue}>{children}</SearchBarContext.Provider>;
};

export default SearchBarContext;

const Theme = {
  primary: '#3D138B',
  secondary: '#D7CEE7',
  backgroundSoftWhite: '#FAFAFB',
  yellow: '#F4D35E',
  paleYellow: '#faeebe',
  mediumYellow: '#f8de8e',
  red: '#f7605f',
  green: '#70A587',
  orange: '#e8934a',

  black: '#000000',
  white: '#FFFFFF',

  grey: '#A5A5A5',
  slate: '#616161',
  lightGrey: '#E8E8E8',
  darkGrey: '#3c3f42',

  breakpoints: {
    singleColumn: 820,
    mobile: 550,
    smallMobile: 400,
  },
};

export default Theme;

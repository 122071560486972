import styled from 'styled-components';
import { AuthButton } from '../../shared/Styles';

export const StyledButton = styled(AuthButton)`
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  width: 80px;
  height: 40px;
  border-width: 0px;
  border-radius: 10px;
  padding: 5px;
  color: black;
  cursor: pointer;
  font-size: 12px;
`;

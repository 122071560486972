import React from 'react';
import styled from 'styled-components';

import { Link } from '../../shared/Styles';
import {
  TERMS_AND_CONDITIONS_DOC,
  CONTACT_US_LINK,
  TABLET_BREAKPOINT,
  ABOUT_US_LINK,
  QUEST_PROJECT_LINK,
} from '../../shared/Constants';

import SebraeLogo from '../../img/marca_sebrae_web.png';

export interface FooterProps {
  title: string;
}

const Footer = ({ title }: FooterProps) => {
  const isQuestInterface = title === 'Quest';
  return (
    <FooterWrapper>
      <FooterContainer makePrefixSpacing={isQuestInterface}>
        <FooterLink href={TERMS_AND_CONDITIONS_DOC} target="_blank" rel="noopener noreferrer">
          <FooterText>Termos e Condições</FooterText>
        </FooterLink>
        <FooterLink href={CONTACT_US_LINK} target="_blank" rel="noopener noreferrer">
          <FooterText>Contato</FooterText>
        </FooterLink>
        <FooterLink href={ABOUT_US_LINK} target="_blank" rel="noopener noreferrer">
          <FooterText>Quem somos</FooterText>
        </FooterLink>
        {isQuestInterface && (
          <>
            <FooterLink href={QUEST_PROJECT_LINK} target="_blank" rel="noopener noreferrer">
              <FooterText>Projeto Quest</FooterText>
            </FooterLink>
            <SebraeLogoImageArea>
              <SebraeLogoImageText>{'Projeto fomentado \npelo Sebrae'}</SebraeLogoImageText>
              <SebraeLogoImage src={SebraeLogo} alt="Logo do Sebrae"></SebraeLogoImage>
            </SebraeLogoImageArea>
          </>
        )}
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled.div`
  padding: 0 0;
  display: flex;
  position: relative;
  flex-direction: column;
`;

const SebraeLogoImageArea = styled.div`
  width: 12.5rem;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SebraeLogoImage = styled.img`
  width: 5rem;
  max_height: 60px;
`;

const SebraeLogoImageText = styled.p`
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  font-family: Univers;
  color: ${({ theme }) => theme.darkGrey};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 0.6rem;
  }
`;

const FooterLink = styled(Link)`
  margin-right: 50px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-right: 10px;
  }
`;

const FooterText = styled.text`
  color: ${({ theme }) => theme.darkGrey};
  text-align: center;
  font-size: 0.9rem;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 0.75rem;
    text
  }
`;

interface FooterContainerProps {
  makePrefixSpacing: boolean;
}
export const FooterContainer = styled.div<FooterContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 0 40px;
  height: 60px;
  background-color: ${({ theme }) => theme.lightGrey};

  ${({ makePrefixSpacing }) =>
    makePrefixSpacing
      ? `&::before {
      content: 'space';
      margin: 1px auto 1px 1px;
      visibility: hidden;
      width: 12.5rem;

      @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
        width: 1rem;
      }
    }`
      : ''}
  & a {
    width: fit-content;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 0px;
  }
`;

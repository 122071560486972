import React from 'react';
import styled from 'styled-components';
import { Snackbar } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export interface MaintenanceProps {
  date?: string;
  hour?: string;
  duration?: string;
}

export interface MaintenanceSnackBarComponentProps {
  language: string;
  showSnackBar: boolean;
  setShowSnackBar: (s: boolean) => void;
}

const MaintenanceSnackBarComponent = ({
  language,
  showSnackBar,
  setShowSnackBar,
}: MaintenanceSnackBarComponentProps) => {
  const handleClose = () => {
    setShowSnackBar(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        style={{ display: 'flex' }}
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const maintenanceString = `Our systems are scheduled to go under maintenance on ${process.env.REACT_APP_MAINTENANCE_DATE} at
  ${process.env.REACT_APP_MAINTENANCE_HOUR} in order to ensure we provide you the best possible experience. It is expected
  that our services will be unavailable for ${process.env.REACT_APP_MAINTENANCE_DURATION} from this date. We apologize
  in advance for any inconvenience and will work to reestabilish our operations as soon as possible.`;

  const maintenanceStringPt = `Nossos sistemas estão programados para entrar em manutenção no dia
  ${process.env.REACT_APP_MAINTENANCE_DATE} às ${process.env.REACT_APP_MAINTENANCE_HOUR} para garantir a melhor experiência possível. Prevê-se que os
  nossos serviços fiquem indisponíveis durante ${process.env.REACT_APP_MAINTENANCE_DURATION} a partir desta data. Pedimos
  desculpas antecipadamente por qualquer inconveniente e trabalharemos para
  restabelecer nossas operações o mais rápido possível.`;

  let message = maintenanceString;
  if (language === 'pt') {
    message = maintenanceStringPt;
  }
  return (
    <MaintenanceSnackBar
      open={showSnackBar}
      message={message}
      action={action}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    />
  );
};

export default MaintenanceSnackBarComponent;

const MaintenanceSnackBar = styled(Snackbar)`
  max-width: 70%;
  text-align: justify;
  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
`;

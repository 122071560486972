/**
 * Context and Provider used for user authentication in NeuralSearchX client.
 */
import React, { createContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import {
  API_BASE,
  CREATE_TOKEN,
  LOCALSTORAGE_TOKEN_KEY,
  LOCALSTORAGE_AUTH_TYPE,
  LOCALSTORAGE_COOKIES_AGREEMENT,
} from '../Constants';

interface UserState {
  token: string;
  expired: boolean;
}

interface childrenProps {
  children: React.ReactNode;
}

export interface AuthProviderProps {
  user: UserState;
  setUser: React.Dispatch<React.SetStateAction<UserState>>;
  isKeycloakClient: boolean;
  refreshToken: (forceRefresh?: boolean) => Promise<String | void>;
  updateUserToKeycloakClient: (token: string) => void;
  getTokenAuthHeader: () => Promise<HeadersInit>;
  getResponse: (
    url: string,
    method: string,
    headers?: HeadersInit,
    body?: FormData | string,
  ) => Promise<Response>;
}

export const AuthProvider = ({ children }: childrenProps) => {
  const [isKeycloakClient, setIsKeycloakClient] = useState<boolean>(() => {
    // Gets auth_type from the localStorage
    const auth_type = localStorage.getItem(LOCALSTORAGE_AUTH_TYPE);

    return auth_type === 'client';
  });
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [user, setUser] = useState<UserState>(() => {
    // Gets token from the localStorage
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);

    if (token) {
      // Return if found on localStorage
      return { token: token, expired: false };
    }

    return {} as UserState;
  });

  const getNewToken = async () => {
    // Get a new one from the API and save it
    setIsKeycloakClient(false);
    localStorage.setItem(LOCALSTORAGE_AUTH_TYPE, 'user');
    const response = await getResponse(`${API_BASE}${CREATE_TOKEN}`, 'POST');
    const { token }: UserState = await response.json();
    localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, token);
    // setUser to use the new token
    setUser({ token: token, expired: false });

    return token;
  };

  const getNewAuth0Token = async () => {
    // Get a new token from Auth0 and return it to be used on calls to the API
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: `https://nsx.ai/`,
      },
    });
    return token;
  };

  const getResponse = async (
    url: string,
    method: string = 'GET',
    headers?: HeadersInit,
    body?: FormData | string,
  ) => {
    /*
     Handles fetch requests. In case of error, this function solves the issues related to
     token authentication by providing a new bearer token.
     */

    const response = await fetch(url, {
      method: method,
      headers: headers,
      body: body,
    });

    if (response.status === 401 || response.status === 403) {
      const token = await refreshToken(false);
      let new_header = getTokenAuthHeader(token, true);
      const new_response = await fetch(url, {
        method: method,
        headers: await new_header,
        body: body,
      });
      return new_response;
    }

    return response;
  };

  const refreshToken = async (forceRefresh: boolean = false) => {
    /*
     Responsible for updating the token state. In the case of forceRefresh (true),
     a new bearer token is provided without checking if the user is a keyCloakClient.
     This behavior is important as the variable state is not updated at the same time on all threads,
     causing wrong requests
     */
    // Remove current token from localStorage
    if (!isAuthenticated || forceRefresh) {
      localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
      // Get a new one from the API
      const token = await getNewToken();
      return token;
    }
  };

  useEffect(
    () => {
      const getFirstTokenAndNotifyUser = async () => {
        await getNewToken();
      };

      //  Checks for token inside user state. If not found, fetches a new one.
      if (!user.token && localStorage.getItem(LOCALSTORAGE_COOKIES_AGREEMENT)) {
        getFirstTokenAndNotifyUser();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  const updateUserToKeycloakClient = (token: string) => {
    setIsKeycloakClient(true);
    setUser({ token: token, expired: false });
    localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, token);
    localStorage.setItem(LOCALSTORAGE_AUTH_TYPE, 'client');
  };

  const getTokenAuthHeader = async (
    token: string = '',
    forceBearer: boolean = false,
  ): Promise<HeadersInit> => {
    /*
     Returns a header token. In the case of forceBearer (true), a bearer header is returned without
     checking if the user is a KeycloakClient. This behavior is similar to what happens in refreshToken()
     and exists to solve problems with different thread states.
     */
    if (token === '' && !isAuthenticated) {
      token = user.token;
    }

    if (isAuthenticated) {
      token = await getNewAuth0Token();
    }

    if (isAuthenticated && forceBearer === false) {
      return { authorization: `Auth0Client ${token}` };
    } else {
      return { authorization: `Bearer ${token}` };
    }
  };

  const providerValue: AuthProviderProps = {
    user,
    setUser,
    isKeycloakClient,
    refreshToken,
    updateUserToKeycloakClient,
    getTokenAuthHeader,
    getResponse,
  };
  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
};

/**
 * Authentication context, used to access and store API tokens in the browser's
 * localStorage.
 *
 * Its values are:
 *
 * `user` as an UserState object. Inside `user.token` you can find the token to
 * authenticate your requests.
 *
 * `refreshToken` callback, used to delete the current token from the localStorage
 * (if exists) and get a new one from the token's endpoint.
 *
 * @example
 * ```typescript
 * const { user, refreshToken } = useContext(AuthContext);
 * ```
 */
export const AuthContext = createContext<AuthProviderProps | null>(null);

export default AuthContext;

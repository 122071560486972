import { useAuth0 } from '@auth0/auth0-react';
import React, { FC } from 'react';
import { StyledButton } from './StyledButton';

export const SignupButton: FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = async () => {
    await loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  };

  return <StyledButton onClick={handleSignUp}>Sign Up</StyledButton>;
};

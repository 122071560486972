import { useAuth0 } from '@auth0/auth0-react';
import React, { FC } from 'react';
import { StyledButton } from './StyledButton';

export const LogoutButton: FC = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return <StyledButton onClick={handleLogout}>Log Out</StyledButton>;
};

import { STOP_WORDS, WEBSOCKET_BASE } from './Constants';

/* Tokenize words without stopwords and split by punctuation */
export const tokenize = (text: string): Array<string> => {
  let results: Array<string> = [];
  let words = text
    .toLowerCase()
    .replace(/[^\w\sáàâãéèêíïóôõöúçñ]|_/gi, ' ')
    .replace(/\s+/g, ' ')
    .split(' ');

  words.forEach((word) => {
    if (!STOP_WORDS.has(word)) {
      results.push(word);
    }
  });
  return results;
};

export const makePOSTRequest = (url: string, data: Object) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const makeAsyncPOSTRequest = async (url: string, body: Object) => {
  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

// Remove "abstract" string from beginning of abstract
export const parseAbstract = (abstract: string): string => {
  return abstract.replace(/^\s*abstract\s*/gi, '');
};

export const sleep = (sec: number) => {
  return new Promise((resolve) => setTimeout(resolve, sec * 1000));
};

export const get_ws_url = (path: string) => {
  if (WEBSOCKET_BASE) {
    return `${WEBSOCKET_BASE}${path}`;
  }
  const loc = window.location;
  return (loc.protocol === 'https:' ? 'wss://' : 'ws://') + loc.host + '/api' + path;
};

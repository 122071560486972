import React, { useCallback, useState, useEffect, useRef, useContext } from 'react';
import { Search } from 'react-feather';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router';
import { Button } from 'reakit';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import SearchBarContext, { SearchBarProviderProps } from '../../../shared/context/SearchBarContext';
import Theme from '../../../shared/Theme';
import {
  SearchIndexOption,
  TABLET_BREAKPOINT,
  API_BASE,
  GLOBAL_TOAST_OPTIONS,
} from '../../../shared/Constants';
import { BoxShadow } from '../../../shared/Styles';
import Keycodes from '../../../shared/Keycodes';
import InterfaceParametersContext, {
  InterfaceParametersProviderProps,
} from '../../../shared/context/InterfaceParametersContext';

interface SearchBarProps extends RouteComponentProps {
  query: string;
  searchIndex: SearchIndexOption;
  simpleSearchBar: boolean;
  hasResultsInDisplay: boolean;
  setQuery: (query: string) => any;
  setSearchIndex: (searchIndex: SearchIndexOption) => any;
  setShowUploadDialog: (s: boolean) => void;
  placeholder: string | undefined;
}

const SearchBar = ({
  query,
  searchIndex,
  simpleSearchBar,
  hasResultsInDisplay,
  setQuery,
  setSearchIndex,
  setShowUploadDialog,
  history,
  placeholder,
}: SearchBarProps) => {
  const [typeaheadIndex, setTypeaheadIndex] = useState<number>(-1);
  const [inputFocused, setInputFocused] = useState<boolean>(false);

  const preferredLanguage = navigator.language;

  const alignLeft = simpleSearchBar && hasResultsInDisplay;

  const { availableIndices, setEmail, setIsChecked } = useContext(
    SearchBarContext,
  ) as SearchBarProviderProps;

  const userInputRef = useRef<HTMLInputElement>(null);
  const userEmailRef = useRef<HTMLInputElement>(null);
  const userCheckboxRef = useRef<HTMLInputElement>(null);

  const { evaluationMode, highlightDocuments, promoteDocuments, multidocqaDocuments, devMode } = useContext(
    InterfaceParametersContext,
  ) as InterfaceParametersProviderProps;

  const historyParametersAppend = `evaluation_mode=${evaluationMode}&highlight_documents=${highlightDocuments}&promote_documents=${promoteDocuments}&multidocqa=${multidocqaDocuments}&dev_mode=${devMode}`;

  // the following number is append to the url as a query parameter, it is used
  // to indicate a new search action, even if the query did not change.
  const randomNumber = Math.random() * 1000;

  const [suggestionList, setSuggestionList] = useState<Array<string>>([]);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);

    if (inputFocused === false) {
      setInputFocused(true);
    }

    // if the query is empty, set list to empty
    if (event.target.value.replace(/\s/g, '') === '') {
      setSuggestionList([]);
    }
    // if next typed character is contained in at least 6 of the previous suggestions, discard suggestions that do not apply
    else if (
      suggestionList.filter((d) => d.startsWith(event.target.value.toLowerCase())).length > 5
    ) {
      setSuggestionList(
        suggestionList.filter((d) => d.startsWith(event.target.value.toLowerCase())),
      );
    }
    // make request to bing autosuggestion API
    else {
      fetch(
        `${API_BASE}/suggest?query=${encodeURIComponent(
          event.target.value,
        )}&user_language=${preferredLanguage}`,
      ).then((graphResponse) => {
        if (graphResponse.ok) {
          graphResponse.json().then((data) => {
            setSuggestionList(data['suggestions']);
          });
        }
      });
    }
  };

  const submitQuery = (q: string = query) => {
    // remove focus from input bar
    if (userInputRef.current) userInputRef.current.blur();

    // Set the spam honeypot fields to prevent bots from submitting the form
    // if the user is a human, these fields should be empty
    setIsChecked(userCheckboxRef.current?.checked ? true : false);
    setEmail(userEmailRef.current?.value ? userEmailRef.current?.value : '');

    //the query parameter refresh is used to ensure that the useEffect hook
    //on the homepage will be trigerred, even if the query doesn't change
    history.push(
      `${history.location.pathname}?index=${encodeURIComponent(
        searchIndex.value,
      )}&query=${encodeURIComponent(q)}&refresh=${randomNumber}&${historyParametersAppend}`,
    );
  };
  const createIndexOption: SearchIndexOption = {
    value: 'createNewIndex',
    visible: true,
    label: 'Upload your own documents...',
  };

  const { isAuthenticated } = useAuth0();

  const handleIndexSelectionChange = (op: SearchIndexOption) => {
    if (op.value === 'createNewIndex') {
      if (!isAuthenticated) {
        toast.error('You must be logged in to upload files', GLOBAL_TOAST_OPTIONS);
      } else {
        setShowUploadDialog(true);
      }
    } else {
      setSearchIndex(op);
    }
  };

  const handleUserKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === Keycodes.ENTER && inputFocused) {
        submitQuery();
      }

      if (event.keyCode === Keycodes.UP) {
        setTypeaheadIndex(Math.max(0, typeaheadIndex - 1));
      } else if (event.keyCode === Keycodes.DOWN) {
        setTypeaheadIndex(Math.min(suggestionList.length - 1, typeaheadIndex + 1));
      } else if (event.keyCode === Keycodes.ENTER && typeaheadIndex >= 0) {
        submitQuery(suggestionList[typeaheadIndex]);
      }
    },
    // eslint-disable-next-line
    [typeaheadIndex, query, inputFocused],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    setTypeaheadIndex(-1);
  }, [query, searchIndex]);

  return (
    <SearchBarWrapper alignLeft={alignLeft}>
      {!simpleSearchBar && (
        <Section simpleSearchBar={simpleSearchBar}>
          <SearchBarText>Search</SearchBarText>
          <Dropdown
            styles={dropdownStyles}
            className="dropdown"
            width="200px"
            options={[
              ...Object.values(availableIndices).map((index) => {
                return {
                  label: index.label,
                  value: index.name,
                  visible: index.visible,
                };
              }),
              createIndexOption,
            ].filter((idx) => idx.visible === true)}
            isSearchable={false}
            value={searchIndex}
            onChange={handleIndexSelectionChange}
          />
          <SearchBarText>for</SearchBarText>
        </Section>
      )}
      <Section simpleSearchBar={simpleSearchBar}>
        <SearchInputWrapper>
          <SearchForm>
            <SearchBarInput
              value={query}
              ref={userInputRef}
              onChange={handleInput}
              onSubmit={() => submitQuery()}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              placeholder={placeholder}
            />
            <SearchHoneyPot id="checkbox" type="checkbox" ref={userCheckboxRef} />
            <SearchHoneyPot
              id="email"
              type="email"
              ref={userEmailRef}
              placeholder="Enter your email"
            />
          </SearchForm>
          {inputFocused && suggestionList.length !== 0 && (
            <TypeaheadWrapper>
              {suggestionList.map((example, idx) => (
                <TypeaheadResult
                  key={idx}
                  onClick={() => submitQuery(example)}
                  onMouseDown={() => submitQuery(example)}
                  selected={idx === typeaheadIndex}
                >
                  {example}
                </TypeaheadResult>
              ))}
            </TypeaheadWrapper>
          )}
        </SearchInputWrapper>
        <SearchButton
          type="submit"
          onSubmit={() => submitQuery()}
          onClick={() => submitQuery()}
          onMouseDown={(e: any) => e.preventDefault()}
        >
          <SearchIcon />
        </SearchButton>
      </Section>
    </SearchBarWrapper>
  );
};

export default withRouter(SearchBar);

const SearchBarWrapper = styled.div<{ alignLeft: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  ${({ alignLeft }) => (alignLeft ? 'margin: 0 auto 1.5rem 0;' : 'margin: 0 auto 1.5rem auto;')};
  padding-right: 20px;
  padding-left: 20px;
  max-width: max(50vw, 800px);

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    flex-wrap: wrap;
    ${({ alignLeft }) => (alignLeft ? 'margin: 0 auto 0.8rem 0;' : 'margin: 0 auto 0.8 auto;')};
  }
`;

const SearchBarText = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.black};
  margin-right: 8px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-right: 0;
    padding: 10px;
  }
`;

const SearchIcon = styled(Search)`
  display: inline;
  height: 16px;
  width: 16px;
  color: ${({ theme }) => theme.grey};
`;

const SearchHoneyPot = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
`;

const SearchBarInput = styled.input`
  display: flex;
  width: 100%;
  padding: 12px 16px;
  outline: none;
  border-radius: 4px 0 0 4px;
  border: 1px solid ${({ theme }) => theme.grey};
  border-right: none;
  background-color: ${({ theme }) => theme.backgroundSoftWhite};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-top: 10px;
  }
`;

const SearchForm = styled.form`
  display: flex;
  width: 100%;
`;

const SearchButton = styled(Button)`
  display: flex;
  background: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.grey};
  border-left: none;
  padding: 12px 16px;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
  outline: none;
  transition: background 0.1s;

  &:hover,
  &:focus {
    svg {
      color: ${({ theme }) => theme.darkGrey};
    }
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-top: 10px;
  }
`;

const SearchInputWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

const TypeaheadResult = styled.div<{ selected: boolean }>`
  outline: none;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
  color: ${({ selected, theme }) => (selected ? theme.primary : theme.black)};
  background: ${({ selected, theme }) => (selected ? theme.secondary : theme.white)};
  width: 100%;
  padding: 12px 16px;
  cursor: pointer;
  text-align: left;
  border-radius: 0;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.primary};
  }

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border: none;
    border-radius: 0 0 4px 4px;
  }
`;

const TypeaheadWrapper = styled.div`
  ${BoxShadow}
  position: absolute;
  top: 52px;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  background: ${({ theme }) => theme.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.lightGrey};
  z-index: 2;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    max-height: 100px;
  }
`;

const Section = styled.div<{ simpleSearchBar: boolean }>`
  display: flex;
  ${({ simpleSearchBar }) => (simpleSearchBar ? 'flex:1;' : '')};
  & + & {
    flex: 1;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-wrap: wrap;
    text-align: center;
    & > div {
      margin: auto;
    }
  }
`;

const Dropdown = styled(Select)``;
const dropdownStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isFocused ? Theme.primary : Theme.black,
    background: state.isFocused ? Theme.secondary : Theme.white,
    cursor: 'pointer',
    '&:hover': {
      background: Theme.secondary,
      color: Theme.primary,
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    border: `1px solid ${Theme.lightGrey}`,
    boxShadow: `0px 2px 5px rgba(236, 237, 237, 0.4),
      0px 0px 5px rgba(142, 147, 148, 0.2)`,
  }),
  control: (_: any, state: any) => ({
    width: 'fit-content',
    border: `1px solid ${Theme.grey}`,
    marginRight: 8,
    borderRadius: 4,
    cursor: 'pointer',
    display: 'flex',
    padding: 4,
    paddingLeft: 8,
    minWidth: 150,
  }),
  valueContainer: () => ({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    whiteSpace: 'nowrap',
  }),
  singleValue: () => ({
    position: 'relative',
    whiteSpace: 'nowrap',
  }),
  placeholder: () => ({
    position: 'relative',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { API_BASE, KEYCLOAK_TOKEN_ENDPOINT, GLOBAL_TOAST_OPTIONS } from '../../shared/Constants';
import { useForm } from 'react-hook-form';
import { Dialog, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import { AuthContext, AuthProviderProps } from '../../shared/context/AuthContext';
import { getText } from '../../shared/Texts';

export interface AuthDialogComponentProps {
  showDialog: boolean;
  setShowDialog: (s: boolean) => void;
}

const AuthDialogComponent = ({ showDialog, setShowDialog }: AuthDialogComponentProps) => {
  const { register, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const { updateUserToKeycloakClient, getResponse } = useContext(AuthContext) as AuthProviderProps;

  const language = navigator.language;

  const checkAccept = async (data: any) => {
    setLoading(true);

    const body = {
      client_id: data.client_id,
      client_secret: data.client_secret,
    };
    const url = `${API_BASE}${KEYCLOAK_TOKEN_ENDPOINT}`;
    const tokenResponse = await getResponse(
      url,
      'POST',
      { 'Content-Type': 'application/json' },
      JSON.stringify(body),
    );

    if (tokenResponse.status === 200) {
      let tokenData = await tokenResponse.json();
      const { token } = tokenData;
      updateUserToKeycloakClient(token);

      closeAndCleanDialog();

      toast.success('Authenticated with success', GLOBAL_TOAST_OPTIONS);
    } else {
      toast.error('Unexpected error. Try again in a few minutes 😞', GLOBAL_TOAST_OPTIONS);
    }
    setLoading(false);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const closeAndCleanDialog = () => {
    setValue('client_id', '');
    setValue('client_secret', '');
    closeDialog();
  };

  return (
    <AuthDialog
      open={showDialog}
      onClose={closeDialog}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <CloseDialogButton onClick={closeDialog} />
      <UploadPageContainer>
        <FormContainer onSubmit={handleSubmit(checkAccept)}>
          <FormField>
            <span>{getText(language, 'loginDialogUserText')}</span>
            <FormInput
              required
              {...register('client_id')}
              placeholder={getText(language, 'loginDialogUserPlaceholder')}
            />
          </FormField>

          <FormField>
            <span>{getText(language, 'loginDialogPasswordText')}</span>
            <FormInput
              required
              {...register('client_secret')}
              type="password"
              placeholder={getText(language, 'loginDialogPasswordPlaceholder')}
            />
          </FormField>
          <FormButtonArea>
            <FormButton type="submit"> Login </FormButton>
          </FormButtonArea>
          {loading && <LoadingCircularProgress />}
        </FormContainer>
      </UploadPageContainer>
    </AuthDialog>
  );
};

export default AuthDialogComponent;

const UploadPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoadingCircularProgress = styled(CircularProgress)`
  margin-top: 12px;
`;

const CloseDialogButton = styled(CloseIcon)`
  margin-left: auto;
  &:hover {
    cursor: pointer;
  }
`;

const AuthDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 80vw;
  }

  & > .MuiDialog-container {
    width: 100%;
    padding: 20px;
  }

  & .MuiDialogPaper {
  }

  & > .MuiDialog-container > .MuiPaper-root {
    width: 100%;
    padding: 20px;
  }
`;
const FormField = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
  max-width: 75vw;

  & span {
    min-width: 25%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & span {
      padding: 0 0 10px 0;
      text-align: center;
    }
  }
`;

const FormButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  & button + button {
    margin-left: 20px;
    background: ${({ theme }) => theme.grey};

`;

const FormContainer = styled.form`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormInput = styled.input`
  width: 30%;
  margin-left: 12px;
  flex: 1;
  padding: 12px 16px;
  outline: none;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.grey};

  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: auto;
    margin-left: 0px;
  }
`;

const FormButton = styled.button`
  display: flex;
  background: ${({ theme }) => theme.primary};
  border: none;
  padding: 12px 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.white};
  border-radius: 4px;
  outline: none;
  transition: background 0.1s;

  &:hover,
  &:focus {
    filter: brightness(80%);
  }
`;

import { OptionTypeBase } from 'react-select';
import { ToastOptions } from 'react-toastify';

/* Routes */
export const HOME_ROUTE = '/';
export const SEBRAE_ROUTE = '/quest';
export const QUEST_RESULT_ROUTE = '/quest/resultados';
export const MAGICWIKI_ROUTE = '/magicwiki';
export const RELATED_ROUTE = '/related';

/* Breakpoints */
export const SMALL_MOBILE_BREAKPOINT = 425;
export const LARGE_MOBILE_BREAKPOINT = 600;
export const TABLET_BREAKPOINT = 800;

/* Styles */
export const CONTENT_WIDTH_PERCENTAGE = 80;

/* localStorage */
export const LOCALSTORAGE_TOKEN_KEY = '@NeuralSearchX:token';
export const LOCALSTORAGE_AUTH_TYPE = '@NeuralSearchX:auth';
export const LOCALSTORAGE_COOKIES_AGREEMENT = '@NeuralSearchX:cookies';
export const LOCALSTORAGE_NSX_GIVEN_EVALUATION = '@NeuralSearchX:Evaluation';

/* API */
export const API_BASE =
  process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api' : '/api';
export const WEBSOCKET_BASE =
  process.env.NODE_ENV === 'development' ? 'ws://localhost:8000/api' : null;
export const SEARCH_ENDPOINT = '/search';
export const INDEX_ENDPOINT = '/index';
export const NEURALSEARCHX_EVALUATION_ENDPOINT = '/interface_evaluation';
export const MULTIDOCQA_ENDPOINT = '/multidocqa';
export const USER_REGISTER_ENDPOINT = '/register_auth0';

export const KEYCLOAK_TOKEN_ENDPOINT = '/client/token';
export const SEARCH_FEEDBACK_ENDPOINT = '/feedback';
export const SEARCH_CLICKED_ENDPOINT = '/search/log/clicked';
export const CREATE_TOKEN = '/token';
export const PAGE_TITLE = 'NeuralSearchX';
export const PAGE_FAVICON = 'favicon.ico';
export const PAGE_DESCRIPTION =
  'NeuralSearchX is a search engine where you can search your own files in an easy and assertive way';

// URLS
export const QUEST_EVALUATION_FORM_URL = 'https://forms.gle/H5a7KusAz637mjzx6';

/* Search Vertical Models */
export interface SearchIndexOption extends OptionTypeBase {
  value: string;
  visible: boolean;
  label: string;
}

export const SOURCE_LENGTH = 100;
export const RESULT_CONTENT_LENGTH = 300;

/* Time in ms to reload window after the toast pops on the screen */
export const TOAST_AND_RELOAD_TIMEOUT = 8000;
export const TOAST_LONG_RELOAD_TIMEOUT = 20000;

/* Toast notifications settings */
export const GLOBAL_TOAST_OPTIONS: ToastOptions = {
  position: 'top-right',
  autoClose: TOAST_AND_RELOAD_TIMEOUT,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

export const DOWNLOAD_SUCCESS_TOAST_OPTIONS: ToastOptions = {
  position: 'top-right',
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

export const DOWNLOADING_TOAST_OPTIONS: ToastOptions = {
  position: 'top-right',
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

export const TOAST_OPTIONS_LONG_NO_PROGRESS_BAR: ToastOptions = {
  position: 'top-right',
  autoClose: TOAST_LONG_RELOAD_TIMEOUT,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

export const CONTACT_US_LINK = 'https://neuralmind.ai/contato';
export const ABOUT_US_LINK = 'https://neuralmind.ai/sobre-a-neuralmind/';
export const QUEST_PROJECT_LINK = 'https://neuralmind.ai/quest';

export const TERMS_AND_CONDITIONS_DOC =
  'https://docs.google.com/document/d/1YYJV2Mb5JLwJYZEL3X8GI4ZylTedpEy6w7SxRcu6S_M/edit?usp=sharing';

export const QUEST_DEFAULT_INDEX = 'corporate_sebrae';

/* NLTK Stopwords */
export const STOP_WORDS = new Set([
  'i',
  'me',
  'my',
  'myself',
  'we',
  'our',
  'ours',
  'ourselves',
  'you',
  'your',
  'yours',
  'yourself',
  'yourselves',
  'he',
  'him',
  'his',
  'himself',
  'she',
  'her',
  'hers',
  'herself',
  'it',
  'its',
  'itself',
  'they',
  'them',
  'their',
  'theirs',
  'themselves',
  'what',
  'which',
  'who',
  'whom',
  'this',
  'that',
  'these',
  'those',
  'am',
  'is',
  'are',
  'was',
  'were',
  'be',
  'been',
  'being',
  'have',
  'has',
  'had',
  'having',
  'do',
  'does',
  'did',
  'doing',
  'a',
  'an',
  'the',
  'and',
  'but',
  'if',
  'or',
  'because',
  'as',
  'until',
  'while',
  'of',
  'at',
  'by',
  'for',
  'with',
  'about',
  'against',
  'between',
  'into',
  'through',
  'during',
  'before',
  'after',
  'above',
  'below',
  'to',
  'from',
  'up',
  'down',
  'in',
  'out',
  'on',
  'off',
  'over',
  'under',
  'again',
  'further',
  'then',
  'once',
  'here',
  'there',
  'when',
  'where',
  'why',
  'how',
  'all',
  'any',
  'both',
  'each',
  'few',
  'more',
  'most',
  'other',
  'some',
  'such',
  'no',
  'nor',
  'not',
  'only',
  'own',
  'same',
  'so',
  'than',
  'too',
  'very',
  's',
  't',
  'can',
  'will',
  'just',
  'don',
  'should',
  'now',
  // Portuguese stop words
  'de',
  'a',
  'o',
  'que',
  'e',
  'do',
  'da',
  'em',
  'um',
  'para',
  'é',
  'com',
  'não',
  'uma',
  'os',
  'no',
  'se',
  'na',
  'por',
  'mais',
  'as',
  'dos',
  'como',
  'mas',
  'foi',
  'ao',
  'ele',
  'das',
  'tem',
  'à',
  'seu',
  'sua',
  'ou',
  'ser',
  'quando',
  'muito',
  'há',
  'nos',
  'já',
  'está',
  'eu',
  'também',
  'só',
  'pelo',
  'pela',
  'até',
  'isso',
  'ela',
  'entre',
  'era',
  'depois',
  'sem',
  'mesmo',
  'aos',
  'ter',
  'seus',
  'quem',
  'nas',
  'me',
  'esse',
  'eles',
  'estão',
  'você',
  'tinha',
  'foram',
  'essa',
  'num',
  'nem',
  'suas',
  'meu',
  'às',
  'minha',
  'têm',
  'numa',
  'pelos',
  'elas',
  'havia',
  'seja',
  'qual',
  'será',
  'nós',
  'tenho',
  'lhe',
  'deles',
  'essas',
  'esses',
  'pelas',
  'este',
  'fosse',
  'dele',
  'tu',
  'te',
  'vocês',
  'vos',
  'lhes',
  'meus',
  'minhas',
  'teu',
  'tua',
  'teus',
  'tuas',
  'nosso',
  'nossa',
  'nossos',
  'nossas',
  'dela',
  'delas',
  'esta',
  'estes',
  'estas',
  'aquele',
  'aquela',
  'aqueles',
  'aquelas',
  'isto',
  'aquilo',
  'estou',
  'está',
  'estamos',
  'estão',
  'estive',
  'esteve',
  'estivemos',
  'estiveram',
  'estava',
  'estávamos',
  'estavam',
  'estivera',
  'estivéramos',
  'esteja',
  'estejamos',
  'estejam',
  'estivesse',
  'estivéssemos',
  'estivessem',
  'estiver',
  'estivermos',
  'estiverem',
  'hei',
  'há',
  'havemos',
  'hão',
  'houve',
  'houvemos',
  'houveram',
  'houvera',
  'houvéramos',
  'haja',
  'hajamos',
  'hajam',
  'houvesse',
  'houvéssemos',
  'houvessem',
  'houver',
  'houvermos',
  'houverem',
  'houverei',
  'houverá',
  'houveremos',
  'houverão',
  'houveria',
  'houveríamos',
  'houveriam',
  'sou',
  'somos',
  'são',
  'era',
  'éramos',
  'eram',
  'fui',
  'foi',
  'fomos',
  'foram',
  'fora',
  'fôramos',
  'seja',
  'sejamos',
  'sejam',
  'fosse',
  'fôssemos',
  'fossem',
  'for',
  'formos',
  'forem',
  'serei',
  'será',
  'seremos',
  'serão',
  'seria',
  'seríamos',
  'seriam',
  'tenho',
  'tem',
  'temos',
  'tém',
  'tinha',
  'tínhamos',
  'tinham',
  'tive',
  'teve',
  'tivemos',
  'tiveram',
  'tivera',
  'tivéramos',
  'tenha',
  'tenhamos',
  'tenham',
  'tivesse',
  'tivéssemos',
  'tivessem',
  'tiver',
  'tivermos',
  'tiverem',
  'terei',
  'terá',
  'teremos',
  'terão',
  'teria',
  'teríamos',
  'teriam',
]);

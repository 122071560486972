import React, { createContext, useState } from 'react';

export interface childrenProps {
  children: React.ReactNode;
}

export interface InterfaceParametersProviderProps {
  evaluationMode: number;
  setEvaluationMode: (showComparison: number) => void;
  highlightDocuments: boolean;
  setHighlightDocuments: (highlight: boolean) => void;
  promoteDocuments: boolean;
  setPromoteDocuments: (highlight: boolean) => void;
  multidocqaDocuments: boolean;
  setMultidocqaDocuments: (highlight: boolean) => void;
  multidocCitationCurrentHover: number;
  setMultidocCitationCurrentHover: (currentHover: number) => void;
  devMode: number;
  setDevMode: (devMode: number) => void;
}

const InterfaceParametersContext = createContext<InterfaceParametersProviderProps | null>(null);

export const InterfaceParametersProvider = ({ children }: childrenProps) => {
  const [evaluationMode, setEvaluationMode] = useState<number>(0);
  const [highlightDocuments, setHighlightDocuments] = useState<boolean>(true);
  const [promoteDocuments, setPromoteDocuments] = useState<boolean>(true);
  const [multidocqaDocuments, setMultidocqaDocuments] = useState<boolean>(true);
  const [multidocCitationCurrentHover, setMultidocCitationCurrentHover] = useState<number>(-1);
  const [devMode, setDevMode] = useState<number>(0);

  const providerValue: InterfaceParametersProviderProps = {
    evaluationMode,
    setEvaluationMode,
    highlightDocuments,
    setHighlightDocuments,
    promoteDocuments,
    setPromoteDocuments,
    multidocqaDocuments,
    setMultidocqaDocuments,
    multidocCitationCurrentHover,
    setMultidocCitationCurrentHover,
    devMode,
    setDevMode,
  };
  return (
    <InterfaceParametersContext.Provider value={providerValue}>
      {children}
    </InterfaceParametersContext.Provider>
  );
};

export default InterfaceParametersContext;

import React from 'react';
import styled from 'styled-components';
import { LARGE_MOBILE_BREAKPOINT, LOCALSTORAGE_COOKIES_AGREEMENT } from '../../shared/Constants';
import { Dialog } from '@material-ui/core';

export interface CookiesDialogComponentProps {
  showDialog: boolean;
  setShowDialog: (s: boolean) => void;
}

const CookiesDialogComponent = ({ showDialog, setShowDialog }: CookiesDialogComponentProps) => {
  window.addEventListener('submit', (event) => {
    event.preventDefault();
    localStorage.setItem(LOCALSTORAGE_COOKIES_AGREEMENT, 'true');
    closeDialog();
  });

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <CookiesDialog
      open={showDialog}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <UploadPageContainer>
        <Title> Cookies Policy </Title>
        <FormContainer>
          <FormField>
            <p>
              On our website, we collect and store only information that is essential for offering
              our service and for improving user experience, and we do this with the consent of our
              website visitors.
            </p>
            <p>
              By clicking on the OK Button, you agree with the usage of cookies and will proceed to
              our website.
            </p>
          </FormField>
          <FormButtonArea>
            <FormButton type="submit"> OK </FormButton>
          </FormButtonArea>
        </FormContainer>
      </UploadPageContainer>
    </CookiesDialog>
  );
};

export default CookiesDialogComponent;

const UploadPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CookiesDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 80vw;
  }

  & > .MuiDialog-container {
    width: 100%;
    padding: 20px;
  }

  & .MuiDialogPaper {
  }

  & > .MuiDialog-container > .MuiPaper-root {
    width: 100%;
    padding: 20px;
  }
`;
const FormField = styled.div`
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-top: 12px;
  max-width: 75vw;

  & span {
    min-width: 25%;
  }
  @media only screen and (max-width: ${LARGE_MOBILE_BREAKPOINT}px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const FormButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  & button + button {
    margin-left: 20px;
    background: ${({ theme }) => theme.grey};
  }
`;

const FormContainer = styled.form`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormButton = styled.button`
  display: flex;
  background: ${({ theme }) => theme.primary};
  border: none;
  padding: 12px 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.white};
  border-radius: 4px;
  outline: none;
  transition: background 0.1s;

  &:hover,
  &:focus {
    filter: brightness(80%);
  }
`;

const Title = styled.h1`
  margin-top: 20px;

  font-size: 30px;
  @media only screen and (max-width: ${LARGE_MOBILE_BREAKPOINT}px) {
    font-size: 20px;
    text-align: center;
  }
`;

import React from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import { WikiResult } from '../../../shared/Models';

export interface SectionProps {
  name: string;
  content: Array<WikiResult>;
}

const Section = ({ name, content }: SectionProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h4">{name}</Typography>
        <Divider style={{ background: '#9a9b9f' }} />
      </Grid>

      {content.map((value) => {
        return (
          <Grid item xs={12} sm={12}>
            <Typography align="justify">{value.paragraphs.join('\n')}</Typography>
            <Typography>
              <a href={value.source_url} target="_blank" rel="noopener noreferrer">
                {value.title}
              </a>
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Section;

import React, { useContext } from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router';

import { LARGE_MOBILE_BREAKPOINT } from '../../shared/Constants';
import { PageContent, Link } from '../../shared/Styles';
import InterfaceParametersContext, {
  InterfaceParametersProviderProps,
} from '../../shared/context/InterfaceParametersContext';
import NeuralSearchxLogo from '../../img/neuralsearch-w.png';
import QuestLogo from '../../img/quest-w.png';
import MagicWikiLogo from '../../img/magicwiki-w.png';

import NeuralMind from '../../img/neuralmind-white-logo.png';
import { LoginButton } from '../buttons/LoginButton';
import { LogoutButton } from '../buttons/LogoutButton';
import { SignupButton } from '../buttons/SignupButton';
import { ApiKeyButton } from '../buttons/ApiKeyButton';
import { useAuth0 } from '@auth0/auth0-react';

export interface NavbarProps extends RouteComponentProps {
  setShowAuthDialog: (s: boolean) => void;
  title: string;
}

const Navbar = ({ history, setShowAuthDialog, title }: NavbarProps) => {
  const { evaluationMode, highlightDocuments, promoteDocuments, multidocqaDocuments, devMode } = useContext(
    InterfaceParametersContext,
  ) as InterfaceParametersProviderProps;

  const isQuestInterface = title === 'Quest';
  const isMagicWikiInterface = title === 'MagicWiki';
  const interfaceLogo = isQuestInterface
    ? QuestLogo
    : isMagicWikiInterface
    ? MagicWikiLogo
    : NeuralSearchxLogo;

  const interfaceLogoAltText = isQuestInterface
    ? 'Logo do Quest'
    : isMagicWikiInterface
    ? 'Logo do MagicWiki'
    : 'Logo do NeuralSearchX';
  const { isAuthenticated } = useAuth0();

  const historyParametersAppend = `evaluation_mode=${evaluationMode}&highlight_documents=${highlightDocuments}&promote_documents=${promoteDocuments}&multidocqa=${multidocqaDocuments}&dev_mode=${devMode}`;
  return (
    <NavbarWrapper>
      <NavbarContent>
        <Row>
          <LeftAreaContainer
            tabIndex={0}
            onClick={() =>
              history.push(
                isMagicWikiInterface
                  ? history.location.pathname
                  : `${history.location.pathname}?${historyParametersAppend}`,
              )
            }
          >
            <PlatformLogo
              src={interfaceLogo}
              isQuestInterface={isQuestInterface}
              alt={interfaceLogoAltText}
            />
          </LeftAreaContainer>
          <RightAreaContainer>
            <Link href="http://neuralmind.ai/" target="_blank" rel="noopener noreferrer">
              <CompanyLogo src={NeuralMind} alt="NeuralMindLogo" marginRight={24} />
            </Link>
            {!isAuthenticated && (
              <>
                <SignupButton />
                &nbsp;&nbsp;
                <LoginButton />
              </>
            )}
            {devMode === 1 && isAuthenticated && (
              <>
                <ApiKeyButton />
                &nbsp;&nbsp;
              </>
            )}
            {isAuthenticated && (
              <>
                <LogoutButton />
              </>
            )}
          </RightAreaContainer>
        </Row>
      </NavbarContent>
    </NavbarWrapper>
  );
};

export default withRouter(Navbar);

const NavbarWrapper = styled.div<{ isQuestInterface?: boolean }>`
  padding: 12px 48px 24px 48px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  position: relative;
  background: ${({ theme }) => `${theme.primary}`};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 12px 16px 24px 16px;
  }
`;

const NavbarContent = styled(PageContent)`
  width: 100%;
  margin: auto;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const LeftAreaContainer = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
`;
const PlatformLogo = styled.img<{ marginRight?: number; isQuestInterface?: boolean }>`
  height: 3.5rem;
  width: auto;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  ${({ isQuestInterface }) => (isQuestInterface ? 'margin-bottom:-10px;' : '')}
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    height: 2.5rem;
  }

  @media only screen and (max-width: $400px) {
  }
`;

const CompanyLogo = styled.img<{ marginRight?: number }>`
  height: 32px;
  width: auto;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  @media only screen and (max-width: ${LARGE_MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const RightAreaContainer = styled.div`
  align-items: flex-end;
  justify-content: center;
  display: flex;
`;

import React, { FC } from "react";
import { API_BASE } from "../../shared/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import { StyledButton } from "./StyledButton";

export const ApiKeyButton: FC = () => {
    const { getAccessTokenSilently } = useAuth0();

    const handleGenerateApiKeyClick = async () => {

        const apiKeyName = prompt("Enter the name for your API key:");
        if (!apiKeyName) {
            return;
        }

        const token = await getAccessTokenSilently();
        fetch(`${API_BASE}/api_key?label=${apiKeyName}`, {
            method: "POST",
            headers: {
                "authorization": "Auth0Client " + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const popup = window.open("", "API Key", "width=400,height=200");
                if (popup != null) {
                    popup.document.write(`<p>Your API Key is: ${data.api_key}</p>`);
                }
                else {
                    console.log("Popup could not be oppened");
                    console.log("Your API Key is: " + data.api_key);
                }
            });

    };

    return (
        <StyledButton onClick={handleGenerateApiKeyClick}>Get API Key</StyledButton>
    );
}





